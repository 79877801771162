.login-form {
  &-input {
    color: $dark-grey;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    background: white;
    height: 46px;
    padding: 0 12px;
    border: 1px solid $brand-blue-dark;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 6px 0;
  }
  .btn-submit {
    color: white;
    font-weight: 600;
    background: #1F3C8B;
    margin-top: 12px;
    width: 100%;
    &:hover {
      background-color: black;
    }
  }
}