@import "form";

.login {
  height: 100vh;
  background-color: $brand-blue;
  display: flex;
  align-items: center;
  &-row {
    text-align: center;
    justify-content: center;
    .logo {
      margin-bottom: 2rem;
    }
  }
  &-header {
    color: white;
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-align: center;
    margin-bottom: 1rem;
    @include breakpoint($phone-lg - 1px) {
      font-size: 28px;
    }
    @include breakpoint($phone-sm) {
      font-size: 24px;
    }
  }
  .header-thin {
    font-weight: 200;
  }
  @include breakpoint($tablet-lg) {
    padding: 0 1rem;
  }
}