// colors 
$brand-blue: #254AAF;
$brand-blue-dark: #1F3C8B;
$dark-grey: #757575;
$lighter-grey: #939393;
$light-grey: #EFEFEF;
$gold: #B7A162;
$red: #F44336;

// colors fiba
$fiba-green: #51CF66;
$fiba-black: #151515;
$fiba-blue: #2196F3;
$fiba-border-grey: rgba(0, 0, 0, 0.26);

// **breakpoints**
// phone
$phone-sm: 320px;
$phone-sm-landscape: 568px;
$phone-md: 375px;
$phone-md-landscape: 812px;
$phone-lg: 414px;
$phone-lg-landscape: 736px;
$phone-xl-landscape: 812px;
// tablet
$tablet-sm: 768px;
$tablet-sm-landscape: 1024px;
$tablet-md: 992px;
$tablet-lg: 1024px;
$tablet-lg-landscape: 1366px;
// desktop
$desktop-lg: 1920px;