.navbar-fiba {
  background-color: $brand-blue;
  padding: 4px 20px;
  margin-bottom: 1rem;
  &-logo {
    padding: 0;
    .logo {
      width: 160px;
    }
    .letters {
      width: 45px;
      margin-left: 8px;
    }
  }
  .btn-logout {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: white;
    background: $brand-blue;
    border-radius: 4px;
    margin: 4px 0;
  }
}