.events {
  background-color: $light-grey;
  padding-bottom: 2rem;
  &-user-message {
    text-align: center;
    margin-top: 2rem;
  }
  &-header-row {
    padding: 1rem 0 .5rem 0;
    .events-header {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
    }
  }
  &-list-row {
    padding: .5rem 0;
  }
  &-list-col {
    display: flex;
    .calendar {
      text-align: center;
      display: flex;
      flex-direction: column;
      font-family: 'Bebas Neue', cursive;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .date-name {
        color: white;
        font-size: 15px;
        background-color: $brand-blue;
        padding: 4px 12px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        width: 80px;
      }
      .date-number {
        font-size: 32px;
        background-color: white;
        padding-right: 2px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        width: 80px;
      }
    }
    .summary {
      display: flex;
      flex-direction: column;
      margin: auto 0 auto 1rem;
      .title {
        font-family: 'Bebas Neue', cursive;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
      }
      .location {
        color: $lighter-grey;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        @include breakpoint($phone-lg) {
          width: 170px;
        }
      }
    }
    .chevron {
      color:rgba(0, 0, 0, 0.54);
      display: flex;
      flex-direction: column;
      margin: auto 0 auto auto;
      // for single event view
      &-left {
        margin: auto 2rem auto 1rem;
        @include breakpoint-min($tablet-lg) {
          margin-left: 7.5rem;
        }
      }
      // 
    }
  }
  @include breakpoint($tablet-lg) {
    padding: 0 1rem 2rem 1rem;
  }
}