// breakpoint mixins
@mixin breakpoint($size) {
  @media (max-width: $size) {
    @content;
  }
}
@mixin breakpoint-min($size) {
  @media (min-width: $size) {
    @content;
  }
}
@mixin breakpoint-landscape($size) {
  @media (max-width: $size) and (orientation: landscape) {
    @content;
  }
}
