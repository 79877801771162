.check-in {
  .divider {
    border-top: 1px solid $fiba-border-grey;
    display: flex;
    margin: 1rem auto auto auto;
    &-bottom {
      margin: 1rem auto 1rem auto;
    }
  }
  &-col {
    .card {
      background-color: white;
      height: 100px;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      margin: 1rem 0 8rem 0;
      border: none;
      .btn-card {
        color: white;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 100%;
        background-color: $fiba-green;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        padding: 8px 0;
        margin-top: auto;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

				&.cancel {
					background-color: $red;
				}
      }
      .icon-card {
        padding: .5rem;
      }
    }
    .barcode-scanner {
      .barcode-scanner-input {
        border-radius: 4px;
      }
    }
    .success {
      text-align: center;
      margin-top: 1rem;
      &-icon {
        color: $fiba-green;
        font-size: 5rem;
      }
      &-icon-error {
        color: $red;
        font-size: 5rem;
      }
      &-header {
        color: $fiba-black;
        font-family: Bebas Neue;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        margin-top: 1rem;
      }
      &-sub-copy {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .btn {
      color: white;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      width: 100%;
      &-continue {
        background: $fiba-blue;
        margin-bottom: 1rem;
      }
      &-destroy {
        background: $red;
      }
      &:hover, 
      &:focus,
      &:active {
        background-color: black;
      }
    }
  }
  @include breakpoint($tablet-lg) {
    padding: 0 1rem;
  }
	.icn-spinner {
		animation: spin-animation 1s infinite;
    animation-timing-function: linear;
    display: inline-block;
	}
}